<template>
  <div class="container">
    <van-nav-bar title="支付" left-arrow @click-left="$router.back()" class="van-nav-bar-title" />
    <div class="content">
      <div class="pay-box">
        <div class="pay-title">AI急重症预警套餐</div>
        <div class="pay-package">
          <el-checkbox-group v-model="selectedPackage" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="p in packages" :label="p.id" :key="p.id" :disabled="p.disabled"
              class="pay-el-checkbox">{{ p.name
              }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="pay-tip primary-color">每种疾病900积分（原价3888积分，优惠价900积分）</div>
        <div class="pay-tip">（多选则叠加，例如选择脑中风+心梗则结算1800积分）</div>
      </div>
    </div>
    <div class="footer">
      <div class="price">
        <p>合計：<span>{{ totalPrice }}積分</span></p>
      </div>
      <div class="score">共優惠 {{ totalLastPrice - totalPrice }}積分</div>
      <div class="submit" @click="pay">去支付</div>
    </div>
  </div>
</template>

<script>
import { payMyLink, addPayMyLink } from '@/js/axios'
import { Base64 } from 'js-base64';


export default {
  name: 'myLinkPay',

  data () {
    return {
      totalScore: 0,
      qid: "",
      packages: [
        {
          id: "A05",
          name: '痛風風險',
          price: 900,
          lastPrice: 3888,
          disabled: false
        },
        {
          id: "A03",
          name: '心肌梗塞風險',
          price: 900,
          lastPrice: 3888,
          disabled: false
        },
        {
          id: 'A17',
          name: '腦中風風險',
          price: 900,
          lastPrice: 3888,
          disabled: false
        },
        {
          id: "A07",
          name: '癌症風險',
          price: 900,
          lastPrice: 3888,
          disabled: false
        },
      ],
      selectedPackage: [],
      type: "pay",
      disBuy: []
    };
  },
  created () {
    if (this.$route.params.id) {
      this.qid = this.$route.params.id
      const type = this.$route.query.type
      if (type == 'add') {
        this.type = 'add'
      }
    }
  },
  mounted () {
    const { dis, buy } = this.$route.query
    if (dis) {

      const arr = dis.split(',')
      let selectedPackage = arr
      this.packages.forEach(p => {
        if (selectedPackage.includes(p.id)) {
          p.disabled = true
        }
      })
      this.disBuy = arr
      if (buy) {
        selectedPackage = [...selectedPackage, buy]
      }
      this.selectedPackage = selectedPackage
    }
  },
  computed: {
    totalPrice () {
      return this.selectedPackage.reduce((acc, cur) => {
        const p = this.packages.filter(i => !i.disabled).find(p => p.id === cur)
        return acc + (p ? p.price : 0)
      }, 0);
    },
    totalLastPrice () {
      return this.selectedPackage.reduce((acc, cur) => {
        const lastP = this.packages.filter(i => !i.disabled).find(p => p.id === cur)
        return acc + (lastP ? lastP.lastPrice : 0);
      }, 0);
    }
  },

  methods: {
    toggle (index) {
      this.$refs.checkboxes[index].toggle();
    },
    handleCheckedCitiesChange () {

    },
    pay () {
      if (!this.qid) {
        this.$message.error('請先完成註冊');
        this.$router.push('/myLink')
        return
      }
      if (this.selectedPackage.length === 0) {
        this.$message.error('請選擇套餐');
        return
      }

      if (this.type === 'add') {
        const disBuy = this.disBuy
        const dis = this.selectedPackage.filter(p => !disBuy.includes(p))
        const data = {
          dis, id: this.qid,
        }
        console.log("data", data, disBuy, dis);
        addPayMyLink(data).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('儲存成功')
            this.$router.back();
          } else {
            this.$message.error(res.msg)
          }
        })
        return
      } else {
        const data = {
          dis: this.selectedPackage,
          qid: this.qid,
        }
        payMyLink(data).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('支付成功')
            const resData = JSON.parse(Base64.decode(res.data))
            this.$router.replace(`/myLink/result/${resData.id}`)
            // this.$router.push('/myLink/order')
          } else {
            this.$message.error(res.msg)
          }
        })
      }


    }
  },
};
</script>

<style scoped>
.pay-package>>>.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #6681FA;

}

.pay-package>>>.el-checkbox__label {
  padding: 0;
  font-weight: 400;
  font-size: 0.28rem;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
}
</style>

<style lang="scss" scoped>
.primary-color {
  color: #6681FA;

}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;



  .van-nav-bar-title {
    flex: 0 0 auto;
  }

  .content {
    flex: 1;
    padding: .24rem;


    .pay-box {
      padding: .24rem;
      background-color: #fff;

      >div {
        position: relative;
        width: 100%;
      }

      .pay-title {
        height: 1.04rem;
        display: flex;
        align-items: center;
        font-size: .28rem;
        border-bottom: 1px solid #00000016;
      }

      .pay-package {
        padding: .24rem 0;

        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          color: #333333;


          .is-checked {
            border-color: #6681FA;

          }

          >label {
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
            height: 0.84rem;
            border-radius: 0.12rem;
            border: 0.02rem solid #D3D5DE;
            padding: 0 0.24rem;
            margin-bottom: .24rem;

            &:last-child {
              margin-bottom: 0;
            }


          }
        }
      }

      .pay-tip {
        font-size: 0.26rem;
        line-height: 0.36rem;
      }
    }
  }

  .footer {
    background-color: #fff;
    padding: .32rem .24rem;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "price submit" "score submit";

    .price {
      grid-area: price;

      p {
        font-weight: 500;
        font-size: 0.24rem;
        color: #333333;
        line-height: 0.34rem;
        text-align: left;
        font-style: normal;

        span {
          font-weight: 600;
          font-size: 0.34rem;
          color: #FF5000;
          line-height: 0.48rem;
          text-align: left;
          font-style: normal;
        }

      }
    }

    .score {
      grid-area: score;
      font-weight: 400;
      font-size: 0.24rem;
      color: #333333;
      line-height: 0.34rem;
      text-align: left;
      font-style: normal;
    }

    .submit {
      width: 2.24rem;
      height: 0.82rem;
      grid-area: submit;
      background: #6882FB;
      border-radius: 0.41rem;
      font-weight: 400;
      font-size: 0.36rem;
      color: #FFFFFF;
      line-height: 0.82rem;
      text-align: center;
      font-style: normal;
    }
  }


}
</style>